<template>
  <div class="w-100 h-100 overflow-auto">
    <d-medical-devices-registration-sertificates />
  </div>
</template>

<script>

export default {
  name: 'RegistrationSertificates',
  page: {
    title: 'CRM Doctis - Учет регистрационных сертификатов',
  },
};
</script>
